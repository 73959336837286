import moment from "moment";

function loadGoogleAnalytics() {
  if (window.gtag){
    return;  
  }
  
  const googleScript = document.createElement('script');
  googleScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${process.env.VUE_APP_GA_ID}`);
  googleScript.async = true;
  document.head.appendChild(googleScript);
  
  window.dataLayer = window.dataLayer || [];
  window.gtag = function() { dataLayer.push(arguments); };
  gtag('js', new Date());
  gtag('config', process.env.VUE_APP_GA_ID);
}

function normalizeValues(obj) {
  Object.keys(obj).forEach(key => {
    if (obj[key] === null || obj[key] === "") {
      obj[key] = 0;
    }
  });
  return obj;
}

export default function sendEvent(event, obj) {
  loadGoogleAnalytics();
  let normalizedObj = normalizeValues(obj);
  normalizedObj = {
    ...normalizedObj,
    data_hora: moment().format("DD/MM/YYYY HH:mm:ss"),
  };
  gtag("event", event, normalizedObj);
}